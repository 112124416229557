.font-bold {
  font-weight: bold
}
.red {
  text-align: center;
  color: //#f08080; 
      #FF0000;
  font-weight: 800;
}

.amber {
  color: #FFC000; // #ebb000;
  font-weight: 800;
}

.green {
  color: green;
  font-weight: 800;
}