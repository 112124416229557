.MuiPaper-root .MuiToolbar-root .MuiTypography-root {
    font-size: 20px;
    color: #F58220; /* Set the desired font size here */
  }
  /* styles.css (or your preferred CSS file) */

.custom-toolbar {
  position: relative;
  margin-right: 16px;
}

.custom-button {
  position: absolute;
  top: 0;
  right: 0;
}

/* Responsive styles using media query */
@media (max-width: 1000px) {
  .custom-button {
    position: relative;
    margin-top: 16px; /* Adjust as needed for spacing on smaller screens */
    text-align: center;
  }
}
