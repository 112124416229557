.fridayWeek {
  font-size: 12px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 6px;
}

.weekNumber {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  align-items: center;
  gap: 20px;
}

.searchInput {
  font-size: 14.5px;
  margin-left: 8px;
  margin-right: 8px;
  width: 200px;
  border-bottom: 2px solid #F58220,
}

.searchIcon {
  cursor: pointer;
  color: #F58220;
}

.excludesSwitch {
  display: flex;
  font-size: 12px;
  align-items: center;
}

.showClosedRisks{
  display: flex;
  font-size: 12px;
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer; 
  margin-right: 10px;
  margin-bottom: 2px;
  color:rgb(245, 120, 11) ; 
  text-decoration:underline;
}

.toolbarContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // marginTop: -42px
}

.consolidatedtoolbarContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -32px;
  margin-right: -20px;
}

.consolidatedSearchContainer {
  display: flex;
  align-items: center;
  padding-right: 20px;

}

.searchContainer {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.yearDropdown {
  display: flex;
  font-size: 12px;
  font-family: 'Poppins';
  gap: 10px;
  height: 30px;
  align-items: center;
  margin-bottom: 10px;
}

.yearSelect {
  display: inline;
}

.weekNumberPagination {
  display: block
}

.weekNumberAndfriday {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  align-items: center;
  gap: 20px;
}

.smallscreenfridayWeek {
  display: none;
}

.saveCancelButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  order:2;
}

.consolidatedSearchInput {
  font-size: 14.5px;
  margin-left: 8px;
  margin-right: 8px;
  width: 200px;
  border-bottom: 2px solid #F58220;
}
.issueTextArea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 5px;
  margin-top: 15px;
}
.consolidatedYearDropdown {
  display: flex;
  font-size: 12px;
  font-family: 'Poppins';
  gap: 10px;
  height: 30px;
  align-items: center;
  //margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .filterIcon {
    display: flex;
    justify-content: flex-end;
  }

  .toolbarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .consolidatedtoolbarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: -60px -17px 0px 0px;
  }

  .searchContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    padding-right: 0;
    margin: -43px -9px 0px 0px;
  }

  .consolidatedSearchContainer {
    display: flex;
    align-items: center;
    margin-right: -35px;
  }

  .consolidatedSearchInput {
    display: none !important;
  }

  .excludesSwitch {
    display: flex;
    font-size: 10px;
    margin: -60px -13px 0px 0px;
  }
  
  .showClosedRisks{
    display: flex;
    font-size: 10px;
    align-items: flex-end;
    justify-content: flex-end;
    justify-content: flex-end;
    margin: -72px 0px 0px 0px;
    cursor: pointer; 
    color: rgb(245, 120, 11) ; 
    text-decoration:underline;
  }

  .fridayWeek {
    display: none
  }

  .smallscreenfridayWeek {
    display: inline-flex;
    font-size: 11px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 6px;
  }

  .searchInput {
    display:none;
    font-size: 10.5px;
    margin-left: 8px;
    margin-right: 8px;
    width: 100px;
    border-bottom: 1px solid #F58220;
    margin-bottom: 10px;
  }

  .searchIcon {
    display: none !important;
    color: #F58220;
  }
  .weekNumber {
    display: none;
  }
  .yearDropdown {
    font-size: 10px;
    font-family: Poppins;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    height: 80px;
    width: 100px;
    flex-direction: column
  }
  .consolidatedYearDropdown {
    font-size: 10px;
    font-family: Poppins;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    height: 58px;
    width: 100px;
    flex-direction: column;
  }
  .yearSelect {
    display: block;
  }
  .weekNumberAndfriday {
    top: 0;
    left: 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -16px;
  }

  .weekNumberPagination {
    margin: -17px 0px 0px -18px;
    display: flex
  }

  .saveCancelButtons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 15px;
    order: 1;
  }
  .issueTextArea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 5px;
    margin-top: 15px;
    border: 0.5px solid #070707; 
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px)  {
  .filterIcon {
    display: flex;
    justify-content: flex-end;
  }

  .toolbarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .consolidatedtoolbarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: -20px -17px 0px 0px;
  }

  .searchContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    padding-right: 0;
    margin: -30px -9px 0px 0px;
  }

  .consolidatedSearchContainer {
    display: flex;
    align-items: center;
    margin-right: -10px;

  }

  .consolidatedSearchInput {
    font-size: 12.5px;
    margin-left: 8px;
    margin-right: 8px;
    width: 150px;
    border-bottom: 1.5px solid #F58220;
  }

  .excludesSwitch {
    display: flex;
    font-size: 12px;
    margin: -60px -13px 0px 0px;
  }

  .showClosedRisks {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 12px;
    margin: -75px 0px 0px 0px;
    cursor: pointer; 
    color:rgb(245, 120, 11) ; 
    text-decoration:underline;
  }

  .fridayWeek {
    display: none
  }

  .smallscreenfridayWeek {
    display: inline-flex;
    font-size: 12px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 6px;
  }

  .searchInput {
    font-size: 12.5px;
    margin-left: 8px;
    margin-right: 8px;
    width: 130px;
    border-bottom: 1.5px solid #F58220;
    margin-bottom: 10px;
  }

  .weekNumber {
    display: none;
  }
  .yearDropdown {
    font-size: 12px;
    font-family: Poppins;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    height: 80px;
    width: 100px;
    flex-direction: column
  }
  .consolidatedYearDropdown {
    font-size: 10px;
    font-family: Poppins;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    height: 60px;
    width: 100px;
    flex-direction: column;
  }
  .yearSelect {
    display: block;
  }
  .weekNumberAndfriday {
    top: 0;
    left: 0;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: -16px;
  }

  .weekNumberPagination {
    margin: -17px 0px 0px -18px;
    display: flex
  }

  .saveCancelButtons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 15px;
    order:2;
  }
  .issueTextArea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 5px;
    margin-top: 15px;
    border: 0.5px solid #070707; 
  }
}