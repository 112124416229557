@import "App.scss";                 
@import "util.scss";                 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* globals */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --mainBg:linear-gradient(to right, #ffffff, #ffece3);//  #F5F5F5;
  --breadcrumb-theme-1:#FE5000;
  --breadcrumb-theme-2: #F5F5F5;
  --breadcrumb-theme-3:#FE5000;
  --breadcrumb-theme-4:#FE5000;
}

body {
  background: var(--mainBg);
  font-family: 'Poppins', sans-serif !important;
  line-height: 1.4;
}
.topbar{
  height: 19%;
  max-width:100vw;
  // border-top: 0.1rem solid #FE5000;
  // box-shadow: 0px 4px 4px rgba(50, 65, 255, 0.15);
  padding-left: 1%;
}

.topbar .logo > a > img {
 height : 2rem;
 width: 2rem;
 margin-top: 1%;
}

.topbar .logo .title  { 
  //position: absolute;
  color:#123D81;
  //margin-top: 1.2%;
  // font-weight: 550;
  font-size: 1rem;
  line-height:1.6rem;
  margin-left: 0.5%;

}
pre.fontweightChange{
  margin-top: -3%;
  color: #123D81;
  font-size: 7px !important;
  line-height: 12px;
  margin-left: 3.5rem;
  font-weight: bolder !important;
  font-family: 'Poppins';
}

.topbar .logo a img +div {
  display: inline-block;
}
.topbar .topnav  {
  float: right;
  display: table;
  // margin-right: 1.4%;
  // margin-top: 1%;
}

.topnav div{
  display: table-cell;
  vertical-align: middle;
  margin-top: 1rem;
  width: 7rem;
  // padding: 0.7rem ;
}


.topbar .userlogo + span {
  display: inline-block;
}

.topbar .navbar{
 margin-top:-1.2rem;
 margin-left:13rem;
 font-weight: 600px;
 font-size: 0.75rem// 12px;
}
.topbar .navbar ul {
  list-style: none;
  display: block;
  margin: 0;
}
.topbar .navbar ul li {
  display:inline-block;
 
}
.topbar .navbar ul li a {
  text-decoration: none;
  display: block;
  color:#123D81;
  opacity: 0.75;
  margin-right: 35px;
  padding-bottom: 4px;
}

.topnav  h5,
.topnav  h6 {
  letter-spacing: 0.1px;
  color:#123D81;
  font-weight: 600;
  font-size: 0.5rem;
}
.topnav .logintime {
  letter-spacing: 0.1px;
  color:#123D81;
  font-weight: 600;
  font-size: 0.6rem;
  
}

// .topnav div{
//   border-left : 2px solid rgba(0, 0, 0, 0.2);
// }
.topnav.img{
  height: 2rem;
  padding: 0 0.6rem;
}
.logout{
    height : 1rem;
  padding-left: 1rem;
  cursor: pointer;
}
.userlogo{
  height:1.5rem;
  padding-right: 0.5rem;
}

.user{
  display: flex;
  align-items: 'center';
  justify-content: center;
  //padding: 0.5rem;
  border-radius: 50%;
  padding:0;
}
.topnav .role p {
  font-weight: bold;
  font-size: 0.625rem;//10px;
  color:#123D81;
  font-family: 'Poppins';
}


nav.navbar ul li a.navbar__link--active {
  opacity: 1;
  border-bottom: 4px solid #FE5000;
  font-weight: 900;
  border-radius:4px;
}
.bgarea {
  height: 100vh;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  
}

.loginarea{
  margin-top:2%;
  margin-left: 60%;
  width:350px;
}

.loginarea button {
  position: absolute;
  right: 10%;
  top:50%;
  text-align: center;
  font-size: 15px;
  padding: 10px 80px;
  text-transform: capitalize;
  border-radius: 30px;
  background-color: #123D81; 
  color: #ffffff;
  box-shadow: none;
}

/* end of login */

/* end of header */

.blocks {
  margin: 30px 0 0;
  border-radius: 10px 10px 0 0 !important;
  border: 0 !important;
  border-bottom: 4px solid #FE5000 !important;
  box-shadow: 0px 3px 30px #00000029;
}
.blocks .MuiAvatar-root {
  background-color: transparent;
  border-radius: 0;
}
.blocks .MuiCardHeader-root {
  border-bottom: 2px solid #000000;
}
.blocks .MuiCardHeader-root span {
  font-size: 16px;
  font-weight: 500;
  color: #464646;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}
.tabarea header {
  background-color: transparent;
  box-shadow: none;
}
.tabarea header button {
  background: #edeff3;
  border-radius: 10px 10px 0px 0px;
  margin-right: 10px;
  border-bottom: 3px solid #ced3dc;
}
.tabarea header button span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  opacity: 0.75;
  color: #464646;
}
.tabarea header button.Mui-selected {
  background: #000000;
  box-shadow: 0px 3px 10px #00000028;
}
.tabarea header button.Mui-selected span {
  opacity: 1;
}
.tabarea header .MuiTabs-indicator {
  background-color: #FE5000;
  height: 3px;
}
.tabarea div[role='tabpanel'] {
  background: #ffffff;
  border-radius: 0 0 10px 10px !important;
  box-shadow: 0px 3px 30px #00000029;
  padding: 20px;
  padding-bottom: 35px;
  margin-bottom: 15px;

  .tblcontent {
    .MuiPaper-root {
      border-radius: 0;
      box-shadow: none;
    }
    table {
      tr:nth-last-child(2) {
        display: none;
      }
      td {
        color: #464646 !important;
        font-weight: 400 !important;
        font-family: 'Poppins', sans-serif !important;
        font-size: 14px !important;
        button svg {
          color: #060606;
          font-size: 18px;
        }
        svg {
          font-size: 16px;
          vertical-align: sub;
          margin-left: 5px;
        }
      }
      th {
        color: #464646;
        font-weight: 500 ;
        font-family: 'Poppins', sans-serif;
        text-transform: capitalize;
        z-index: 0 !important;
      }
    }
  }

  .MuiBox-root {
    padding: 0;
  }
}
.tabarea div[role='tabpanel'] .MuiAccordion-root {
  border-radius: 0;
  border-left: 4px solid #FE5000;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0 0 5px 0;
}
.tabarea div[role='tabpanel'] .MuiAccordion-root:before {
  background-color: transparent;
}

.scorearea {
  h1 {
    font-size: 18px;
    font-weight: 500;
    color: #464646;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  .scorearea-content {
    display: table;
    width: 100%;
    border: 1px solid #ced3dc;
    border-width: 1px 0;
    padding: 20px 0;

    .scorearea-row {
      display: flex;
      gap: 36px;

      .scorearea-cell-criteria {
        width: 40%;
      }

      .scorearea-cell-score {
        width: 30%;
      }

      .scorearea-cell-overall-score {
        width: 60%;
      }

      .scorearea-cell-criteria,
      .scorearea-cell-score,
      .scorearea-cell-overall-score {
        display: table-cell;
        font-size: 14px;
        font-weight: 500;
        color: #6f6f6f;
        font-family: 'Poppins', sans-serif;
        padding-bottom: 25px;

        .MuiBox-root {
          padding: 0;
          p {
            font-weight: 500;
            color: #6f6f6f;
            font-family: 'Poppins', sans-serif;
          }
        }

        .MuiLinearProgress-bar {
          background-color: #FE5000;
        }

        .MuiLinearProgress-root {
          background-color: #f1f3f6;
          height: 20px;
        }
      }
    }

    .scorearea-row:first-child {
      .scorearea-cell-criteria,
      .scorearea-cell-score {
        font-size: 16px;
        color: #464646;
      }
    }

    .scorearea-row:last-child {
      gap: 0;
      
      .scorearea-cell-criteria,
      .scorearea-cell-overall-score {
        padding: 15px 0;
        font-size: 16px;
        border: 2px solid #f1f3f6;
        border-width: 2px 0;
        background: #fafbfc;
      }
    }
  }
}

.filter-area {
  float: left;
  width: 100%;
  border-bottom: 3px solid #ced3dc;
  background: #fafbfc;
  padding: 0 5px;
  p {
    padding: 10px 25px 10px 0;
    font-size: 14px;
  }
  label span {
    font-size: 14px;
  }
}
.tbl-layout {
  .tbl-layout-content {
    display: table;
    width: 100%;
    .tbl-layout-row {
      display: table-row;
      width: 100%;
      .tbl-layout-cell {
        display: table-cell;
        width: 16%;
        font-size: 14px;
        font-weight: 500;
        color: #6f6f6f;
        font-family: 'Poppins', sans-serif;
        border-bottom: 1px solid #ced3dc;
        padding: 20px 5px 20px 0;
        .MuiBox-root {
          padding: 0;
          p {
            font-weight: 500;
            color: #6f6f6f;
            font-family: 'Poppins', sans-serif;
          }
        }
      }
      .tbl-layout-cell-score {
        display: table-cell;
        width: 6%;
        font-size: 14px;
        font-weight: 500;
        color: #6f6f6f;
        font-family: 'Poppins', sans-serif;
        border-bottom: 1px solid #ced3dc;
        padding: 20px 5px 20px 0;
        .MuiBox-root {
          padding: 0;
          p {
            font-weight: 500;
            color: #6f6f6f;
            font-family: 'Poppins', sans-serif;
          }
        }
      }
    }
    .tbl-layout-row:first-child .tbl-layout-cell {
      font-size: 14px;
      color: #464646;
    }

    .tbl-layout-row:first-child .tbl-layout-cell-score {
      font-size: 14px;
      color: #464646;
    }
  }
}
.improvement-content {
  img {
    vertical-align: middle;
  }
  span {
    font-size: 16px;
    font-weight: 500;
    color: #464646;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding: 0 20px;
  }
  p {
    margin-left: 52px;
  }
  p.improvement-text {
    font-size: 12px;
    font-weight: 500;
    color: #464646;
    font-family: 'Poppins', sans-serif;
    background: #fafbfc;
    padding: 10px 5px;
  }
  p.improvement-text.gtext {
    color: #FE5000;
    font-size: 14px;
  }
}
.filesmodal {
  nav {
    .MuiListItemText-root {
      width: 50%;
    }
    .MuiListItemText-root:first-child span {
      color: #464646;
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
    }
    input[type='file'] {
      font-size: 12px;
    }
  }
}
.downloadicon {
  color: #f52a2a;
}
.reviewhead {
  float: right;
  font-weight: 500;
  color: #464646;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  border-bottom: 2px solid #FE5000;
}
.projecthead {
  float: right;
  font-weight: 500;
  color: orange;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  border-bottom: 2px solid #FE5000;
}
.icon-block {
  text-align: center;
  h1 {
    font-size: 22px;
    font-weight: 500;
    margin-top: 20px;
  }
  svg {
    font-size: 100px;
    margin: 30px 0 10px;
    color: #FE5000;
  }
}
/* miscellaneous */
.my5 {
  margin: 5px 0 !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.w100 {
  width: 100% !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.mr20 {
  margin-right: 20px !important;
}
[hidden] {
  display: none !important;
}
::-webkit-file-upload-button {
  background: #FE5000;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border: 0;
  outline: 0;
  padding: 3px 5px;
}
.helpertext {
  font-size: 11px;
  color: red;
  float: left;
  width: 100%;
  padding: 10px 5px;
}

.validateText a {
  font-size: 14px !important;
  color: blue;
  cursor: pointer;
}

.noteText {
  font-size: 11px;
  color: grey;
  float: left;
  width: 100%;
  padding: 10px 5px;
}

.MuiInputBase-input {
  font-size: 14px;
}
#standard-read-only-input-label + .MuiInput-underline:before,
#standard-read-only-input-label + .MuiInput-underline:after {
  border-bottom: 0;
}
.MuiDialogTitle-root h2 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  color: #1671ff;
}
button.MuiIconButton-root:hover {
  background-color: transparent;
  .MuiTouchRipple-root {
    display: none;
  }
}
button.primarybtn {
  background-color:linear-gradient(171.47deg, #FE5000 25.73%, #F58220 94.37%);
  .MuiButton-label {
    text-transform: capitalize;
    font-weight: 400;
  }
}
button.primarybtn:hover {
  background-color: #FE5000;
}
button.primarybtntext {
  .MuiButton-label {
    text-transform: capitalize;
    font-weight: 400;
    color: #1671ff;
  }
}
.breadcrumb {
  display: inline-block;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
  margin: 0 0 15px;
}
.breadcrumb__step {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  padding: 0 10px 0 40px;
  position: relative;
  background: var(--breadcrumb-theme-2);
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #45454580;
}
.breadcrumb__step:first-child {
  padding-left: 25px;
}
.breadcrumb__step:last-child {
  padding-right: 20px;
}
.breadcrumb__step:last-child::after {
  content: none;
}
.breadcrumb__step::after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  border-radius: 0 5px 0 50px;
  background: var(--breadcrumb-theme-2);
  box-shadow: 2px -2px 0 2px var(--breadcrumb-theme-4);
}
.breadcrumb__step--active {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-1);
}
.breadcrumb__step--active::after {
  background: var(--breadcrumb-theme-1);
}
.breadcrumb__step:hover {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-3);
  opacity: 0.75;
}
.breadcrumb__step:hover::after {
  color: var(--breadcrumb-theme-1);
  background: var(--breadcrumb-theme-3);
}

.revieweecolor fieldset {
  border-color: #c561826b !important;
}

.reviewercolor fieldset {
  border-color: #005a9679 !important;
}

.revieweeheadercolor {
  color: #c56183 !important;
}

.reviewerheadercolor {
  color: #005b96 !important;
}

.capitalize {
  text-align: center;
  text-transform: capitalize;
}
.titlebarFont{
  text-decoration: none;
    display: block;
    color: #123D81;
    opacity: 0.75;
    margin-right: 35px;
    padding-bottom: 4px;
    cursor: pointer;
}
.wrap {
  white-space: pre-wrap;
  font-size: 0.8rem;
}

.tss-119jxpx-MUIDataTableToolbar-icon {
  color: #F58220 !important; 
}

.tss-119jxpx-MUIDataTableToolbar-icon:hover {
  color: #F58220 !important; 
}

#demo-customized-button{
  background-color: #ffffff;
  width:2rem
}

#composition-button{
  background-color: #ffffff;
}
// .VpnNote {
//   font-size: 14px;
//   font-style: italic;
//   color: red;
//   margin: 5px 20px;
// }

@media (max-width: 728px) {
  .topnav .logintime {
    display: none;
  }
}
@media (max-width: 910px) {
  .topbar .topnav{
    max-width: 60%;
    float: right;
    margin-right: 1.4%;
    margin-top: 1%;
  }
  img.logout{
    height : 1rem;
    padding-left: 0.4rem;
    cursor: pointer;
  }
  .topbar .navmenu {
    margin-left:  3rem;
    margin-top: -1rem;
  }
  .topbar  .tabtitle{
    opacity: 1;
    border-bottom: 0.2rem solid #FE5000;
    font-size: 1rem;
    border-radius:0.25rem;
    margin-left:  8rem;
    position: relative;
  }
}

@media (min-width: 600px) {
  .topbar .navbar ul{
    display: flex;
    flex-direction: row;

  }
}
@media (max-width: 400px) {
  .topbar .topnav  .user {
    display: flex;
  }
      .topbar .topnav  .user .h5 +.h6{
        overflow: hidden; /* Hides overflowing text */
        text-overflow: ellipsis; 
    }
}
@media (max-width: 372px) {
.topbar .topnav .user{
margin-right: -0.5rem;
}
}

