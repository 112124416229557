.Embed-container {
    height: 90vh; 
    /* width: 90vw;   */
    align-items: center; 
     justify-content: center;
    /* margin-top: 2.2rem;  */
  }
  @media (max-width: 768px) {
    .Embed-container {
      height: 60vh;
      /* width: 80vw; */
      align-items: center; 
       justify-content: center;  
    }
  }
  
  @media (max-width: 480px) {
    .Embed-container {
      height: 45vh;
      /* width: 80vw;   */
      align-items: center; 
      justify-content: center;
    }
  }  

